import axios from "axios";
import router from "@/router";
// import qs from "qs";
import { MessageBox } from "element-ui";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 5000, // 请求超时时间
});

//请求拦截
instance.interceptors.request.use(
  (config) => {
    // 拦截每次请求,携带token
    config.headers.Authorization = JSON.parse(sessionStorage.getItem("token"));
    // config.headers.Authorization = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误(400)";
          break;
        case 401:
          MessageBox.confirm("登录已过期，请重新登录！", "提示", {
            confirmButtonText: "确定",
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            type: "warning",
          }).then(() => {
            sessionStorage.removeItem("token");
            router.replace({
              path: "/login",
            });
          });
          error.message = "未授权，请重新登录(401)";
          break;
        case 403:
          error.message = "拒绝访问(403)";
          break;
        case 404:
          error.message = "请求出错(404)";
          break;
        case 408:
          error.message = "请求超时(408)";
          break;
        case 500:
          error.message = "服务器错误(500)";
          break;
        case 501:
          error.message = "服务未实现(501)";
          break;
        case 502:
          error.message = "网络错误(502)";
          break;
        case 503:
          error.message = "服务不可用(503)";
          break;
        case 504:
          error.message = "网络超时(504)";
          break;
        case 505:
          error.message = "HTTP版本不受支持(505)";
          break;
        default:
          error.message = `连接出错(${error.response.status})!`;
      }
    } else {
      // error.message = '连接服务器失败!'
      error.message = "系统加载中，请稍等···";
    }
    return Promise.reject(error);
  }
);
export default instance;

//请求方式
// export default function (method, url, data = null, config) {
//   method = method.toLowerCase();
//   // 为了适配ie
//   url = method == "get" ? `${url}?time=${new Date().getTime()}` : url;
//   const base = "";
//   const postFormData = new FormData();
//   const formData = new FormData();
//   switch (method) {
//     case "get":
//       return instance.get(
//         `${base}${url}`,
//         {
//           params: data,
//         },
//         {
//           headers: {
//             "cache-control": "no-cache",
//             Pragma: "no-cache",
//           },
//         }
//       );
//     case "getfile":
//       return instance.get(`${base}${url}`, {
//         params: data,
//         responseType: "blob",
//       });
//     case "exportfile":
//       return instance.post(`${base}${url}`, data, {
//         responseType: "blob",
//       });
//     case "get-id":
//       return instance.get(`${base}${url}/${data}`, {
//         headers: {
//           "cache-control": "no-cache",
//           Pragma: "no-cache",
//         },
//       });
//     case "postfile":
//       return instance.post("" + url, data, {
//         responseType: "arraybuffer",
//       });
//     case "post":
//       return instance.post(`${base}${url}`, data, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         onUploadProgress: config ? config.onUploadProgress : "", // 上传文件进度
//       });
//     case "put":
//       return instance.put(`${base}${url}`, data, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//     case "put-id":
//       return instance.put(`${base}${url}/${data}`, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//     case "delete":
//       return instance.delete(`${base}${url}`, data);
//     case "formpost":
//       return instance.post(`${base}${url}`, qs.stringify(data));
//     case "multi-form":
//       return instance.post(`${base}${url}`, data, {
//         heasers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//     case "multi-form-post":
//       Object.keys(data).forEach((key) => {
//         postFormData.append(key, data[key]);
//       });
//       return instance.post(`${base}${url}`, postFormData, {
//         heasers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//     case "upload-form":
//       Object.keys(data).forEach((key) => {
//         formData.append(key, data[key]);
//       });
//       return instance.post(`${base}${url}`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//     case "form-data":
//       return instance.post(`${base}${url}`, qs.stringify(data), {
//         heasers: "application/x-www-form-urlencoded;charset=UTF-8",
//       });
//     case "get-delete":
//       return instance.delete(`${base}${url}/${data}`);
//     case "batch-delete":
//       return instance.delete(`${base}${url}`, qs.stringify(data), {
//         heasers: "application/x-www-form-urlencoded;charset=UTF-8",
//       });
//     case "params-delete":
//       return instance.delete(
//         `${base}${url}`,
//         {
//           params: data,
//         },
//         {
//           headers: {
//             "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//           },
//         }
//       );
//     case "body-put":
//       return instance.put(`${base}${url}`, qs.stringify(data), {
//         heasers: "application/x-www-form-urlencoded;charset=UTF-8",
//       });
//     case "body-delete":
//       return instance.delete(
//         `${base}${url}`,
//         {
//           data: data,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//     default:
//       console.error("未知的api_method" + method);
//       return false;
//   }
// }
