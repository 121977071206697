<template>
    <div class="container">
        <el-header>
            <h3 class="system_title">意霆设备管理云平台</h3>
            <ul class="header_right">
                <li>
                    <div class="icon_box">
                        <el-badge :value="12" class="badge_style">
                            <i class="el-icon-message-solid"></i>
                        </el-badge>
                        通知
                    </div>
                </li>
                <li>
                    <span @mouseenter="delayedOpen" @mouseleave="delayedClose" class="icon_box">
                        <i class="el-icon-user-solid"></i>
                        当前用户
                    </span>
                    <!-- 弹窗元素 -->
                    <div v-if="isPopupVisible" @mouseenter="clearCloseTimeout" @mouseleave="delayedClose" class="popup">
                        <div @click="jumpUserDetails">个人资料</div>
                        <div @click="logout">退出登录</div>
                    </div>
                </li>
            </ul>
        </el-header>
        <el-container>
            <el-aside width="200px">
                <el-menu :default-active="onRoutes" router :unique-opened="true" background-color="#fff"
                    @select="handleMenuSelect">
                    <!-- <el-menu :default-active="'/platOverview'" router :unique-opened="true" background-color="#fff"> -->
                    <template v-for="(item, index) in menuList">
                        <el-submenu v-if="item.children && item.children.length > 0" :index="item.path" :key="index">
                            <template slot="title">
                                <div class="verticalStyle">
                                    <i :class="item.iconclass"></i>{{ item.name }}
                                </div>
                            </template>
                            <el-menu-item v-for="(subItem, subIndex) in item.children" :key="subIndex"
                                :index="subItem.path">
                                {{ subItem.name }}
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-else :index="item.path" :key="index">
                            <div class="verticalStyle">
                                <i :class="item.iconclass"></i>{{ item.name }}
                            </div>
                        </el-menu-item>
                    </template>
                </el-menu>
            </el-aside>
            <el-main>
                <!-- 面包屑功能 -->
                <!-- <div class="main_top">
                    <BreadCrumb />
                </div> -->
                <div class="main_btm">
                    <router-view></router-view>
                </div>
            </el-main>
        </el-container>
    </div>
</template>


<script>
// import BreadCrumb from '@/components/breadCrumb.vue'
export default {
    components: {
        // BreadCrumb
    },
    data() {
        return {
            isPopupVisible: false,
            closeTimeout: null,
            menuList: [
                {
                    path: '/platOverview',
                    name: '平台概况',
                    iconclass: 'iconfont icon-lifangtilitiduomiantifangkuai',
                },
                {
                    path: '/glsLargeScreen',
                    name: 'GLS大屏',
                    iconclass: 'iconfont icon-ditu-01',
                },
                {
                    path: '/cloudComponents',
                    name: '云组态',
                    iconclass: 'iconfont icon-cloud-service',
                    children: [
                        { path: '/cloudComponents/templateConfiguration', name: '模板组态' },
                    ]
                },
                {
                    path: '/alarmCenter',
                    name: '报警中心',
                    iconclass: 'iconfont icon-baojingzhongxin',
                    children: [
                        { path: '/alarmCenter/alarmRecord', name: '报警记录' },
                        { path: '/alarmCenter/alarmPerson', name: '报警联系人' }
                    ]
                },
                {
                    path: '/dataCenter',
                    name: '数据中心',
                    iconclass: 'iconfont icon-pingtaigaikuang',
                    children: [
                        { path: '/dataCenter/historyData', name: '历史数据' },
                    ]
                },
                {
                    path: '/projectCenter',
                    name: '项目中心',
                    iconclass: 'iconfont icon-fenlei',
                    children: [
                        { path: '/projectCenter/projectManage', name: '项目管理' },
                    ]
                },
                {
                    path: '/equipmentCenter',
                    name: '设备中心',
                    iconclass: 'iconfont icon-shebei',
                    children: [
                        { path: '/equipmentCenter/equipmentManage', name: '设备管理' },
                        { path: '/equipmentCenter/equipmentDetails', name: '设备详情' },
                        { path: '/equipmentCenter/dataPointTemplate', name: '数据点模板' },
                    ]
                },
                {
                    path: '/videoMonitoring',
                    name: '视频监控',
                    iconclass: 'iconfont icon-jiankongdaping ',
                },
                {
                    path: '/systemManage',
                    name: '系统管理',
                    iconclass: 'iconfont icon-xitongguanli',
                    children: [
                        { path: '/systemManage/userManage', name: '用户管理' },
                        { path: '/systemManage/roleManage', name: '角色管理' },
                        // { path: '/systemManage/userDetails', name: '个人资料' },
                    ]
                },
            ]
        }
    },
    methods: {
        handleMenuSelect(key, keyPath) {
            console.log(key, keyPath)
            if (key === '/glsLargeScreen') {
                // 打开新标签
                let routeData = this.$router.resolve(({
                    path: '/largeScreen', // path 要跳转的路由地址
                    // query 要传递的参数
                    // query: {}
                }))
                window.open(routeData.href, '_blank')
            }
        },
        // 当前用户悬出框相关方法
        delayedOpen() {
            this.clearCloseTimeout();
            this.isPopupVisible = true;
        },
        delayedClose() {
            // 设置一个延时，给用户时间移动到弹窗上
            this.closeTimeout = setTimeout(() => {
                this.isPopupVisible = false;
            }, 300); // 延时300毫秒关闭
        },
        clearCloseTimeout() {
            // 清除已设定的延时关闭，防止弹窗意外关闭
            if (this.closeTimeout) {
                clearTimeout(this.closeTimeout);
                this.closeTimeout = null;
            }
        },
        jumpUserDetails() {
            this.$router.push({ path: '/systemManage/userDetails' });
        },
        logout() {
            // 清除token
            sessionStorage.removeItem('token')
            // localStorage.removeItem('token');
            // 跳转到登录页面
            this.$router.push('/login');
        }
    },
    computed: {
        onRoutes() {
            return this.$route.path
        }
    }

}
</script>


<style lang='less' scoped>
.container {
    width: 100%;
    height: 100%;
}

.el-header {
    height: 60px;
    background-color: #2D2E3B;
    color: #fff;
    text-align: center;
    line-height: 60px;
    padding: 0 40px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .system_title {
        font-size: 20px;
    }

    .header_right {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            height: 20px;
            line-height: 20px;
            padding: 0 20px;
            border-left: 1px solid #fefefe;
            cursor: pointer;

            .icon_box {
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    margin-right: 6px;
                }

                .el-icon-user-solid,
                .el-icon-message-solid {
                    font-size: 20px;
                }
            }

            &:nth-child(1) {
                border: 0;
            }

            &:nth-child(2) {
                position: relative;

                .popup {
                    position: absolute;
                    left: 14px;
                    margin-top: 20px;
                    width: 108px;
                    height: 80px;
                    background-color: white;
                    border: 1px solid #ccc;
                    padding: 0 10px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    z-index: 9999;

                    div {
                        height: 40px;
                        color: #303133;
                        line-height: 40px;
                        text-align: center;
                        cursor: pointer;
                        box-sizing: border-box;

                        &:first-child {
                            border-bottom: 1px solid #303133;
                        }
                    }
                }
            }

            .badge_style {
                /deep/.el-badge__content {
                    border: 0;
                }

                /deep/.el-badge__content.is-fixed {
                    position: absolute;
                    top: -7px;
                    right: 18px;
                    transform: translateY(-50%) translateX(100%);
                }
            }
        }
    }
}

.el-container {
    height: calc(100% - 60px);
}

.el-aside {
    // background-color: #2D2E3B;
    background-color: #fff;
}

.el-menu {
    border: 0;

    .verticalStyle {
        width: 120px;
        height: 56px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .el-menu-item {
        font-size: 16px;

        i {
            margin-right: 10px;
        }

        .iconfont {
            font-size: 20px;
            color: #303133;
        }

        &:hover {
            //#5B5E75 导航按钮选中的背景色，#5B5E75 table表头背景色
            background-color: #e4e7ed; //hover 背景色
        }
    }

    .el-menu-item.is-active {
        background-color: #5B5E75 !important; //选中背景色
        color: #fff; //选中颜色

        .iconfont {
            color: #fff;
        }
    }

    .el-menu.el-menu--inline {
        .el-menu-item {
            padding-left: 50px !important;
        }
    }

    /deep/.el-submenu__title {
        i {
            margin-right: 10px;
        }

        .iconfont {
            font-size: 20px;
            color: #303133;
        }

        &:hover {
            background-color: #e4e7ed;
        }
    }

    /deep/.el-submenu__icon-arrow {
        font-size: 18px;
    }
}

.el-main {
    height: 100%;
    background-color: #E9EEF3;
    padding: 0;

    .main_top {
        height: 40px;
        background-color: #E4E7ED;
    }

    .main_btm {
        // height: calc(100% - 40px);
        height: 100%;
        background-color: #f1f1f1;

    }
}
</style>