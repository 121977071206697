import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 状态数据定义
    isLoggedIn: false, // 登录状态
  },
  mutations: {
    // 状态更改函数定义
    setLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
  },
  actions: {
    // 异步操作和逻辑
    userLogin({ commit }, data) {
      // 假设这里是登录逻辑
      // ...
      commit("setLoggedIn", data);
    },
  },
  getters: {
    // 计算派生状态
  },
});
