import Vue from "vue";
import ElementUI from "element-ui";
//样式文件需要单独引入
import "element-ui/lib/theme-chalk/index.css";

import less from "less";
import App from "./App.vue";

import * as echarts from "echarts";
import router from "./router";
import store from "./vuex/store"; // 引入store
// 系统样式重置
import "@/assets/css/reset.css";
import "@/assets/iconfont/iconfont.css";

Vue.use(ElementUI);
Vue.use(less);
Vue.config.productionTip = false;
// 将echarts绑定到Vue的原型链上
Vue.prototype.$echarts = echarts;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
