<template>
    <el-form :model="userInfo" :rules="rules" ref="ruleForm" label-position="left" label-width="0px"
        class="login-form-container">
        <div class="login_title">
            <img src="https://xgbcard.xigoubao.com/admin/public/portal/images/login/img_001.png" alt="">
            <span>意霆管理系统</span>
            <img src="https://xgbcard.xigoubao.com/admin/public/portal/images/login/img_002.png" alt="">
        </div>
        <el-form-item prop="userName">
            <el-input class="custom-input" suffix-icon="el-icon-user" type="text" v-model="userInfo.userName"
                auto-complete="off" placeholder="请输入用户名">
            </el-input>
        </el-form-item>
        <el-form-item prop="phone">
            <el-input class="custom-input" suffix-icon="el-icon-phone" type="text" v-model="userInfo.phone"
                auto-complete="off" placeholder="请输入手机号">
            </el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
            <el-input class="custom-input" suffix-icon="el-icon-lock" type="password" v-model="userInfo.checkPass"
                auto-complete="off" placeholder="请输入密码">
            </el-input>
        </el-form-item>
        <div style="width:100%;" class="login_btn_box">
            <el-button class="login_btn" type="primary" @click.native.prevent="handleSubmit">注册</el-button>
            <span @click="changeCanLogin">已有账号! 立即登录 ></span>
        </div>
    </el-form>
</template>

<script>
export default {
    data() {
        return {
            userInfo: {
                userName: '',
                phone: '',
                checkPass: ''
            },
            rules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                checkPass: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            },
        };
    },
    methods: {
        // register() {
        //     this.$http.post('/api/register', this.userInfo)
        //         .then(response => {
        //             // 注册成功后的处理，例如重定向到登录页面
        //             this.$router.push('/login');
        //         })
        //         .catch(error => {
        //             // 处理注册失败
        //             console.error('Registration failed', error);
        //         });
        // }
        handleSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // var loginParams = { username: this.loginFormData.userName, password: this.loginFormData.checkPass };
                    // requestLogin(loginParams).then(data => {
                    //     let { msg, code, user } = data;
                    //     if (code !== 200) {
                    //         this.$message({
                    //             message: msg,
                    //             type: 'error'
                    //         });
                    //     } else {
                    //         sessionStorage.setItem('user', JSON.stringify(user));
                    //         this.$router.push({ path: '/home' });
                    //     }
                    // });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeCanLogin() {
            this.$parent.canLogin = true
        }
    }
};
</script>
<style lang="less" scoped>
.login-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 438px;
    height: 525px;
    padding: 50px 40px;
    box-sizing: border-box;
    background-image: url('@/assets/imgs/loginPage/login_box.png');
    background-size: 438px 525px;
    background-repeat: no-repeat;

    .login_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #0090ff;
        padding-bottom: 20px;

        img {
            width: 65px;
            height: 12px;
        }

        span {
            margin: 0 30px;
        }
    }

    .custom-input /deep/ .el-input__inner {
        width: 304px;
        height: 54px;
        font-size: 16px;
        color: #00E4FF;
        padding-left: 20px;
        padding-right: 45px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        background-image: url('@/assets/imgs/loginPage/bg_ipt.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 27px 18px;
    }

    /* 修改用户名、密码图标样式 */
    /deep/.el-input__suffix {
        height: 54px;
        right: 40px;
    }

    /deep/.el-icon-user {
        color: #0090ff;
        font-size: 24px;
    }

    /deep/.el-icon-phone {
        color: #0090ff;
        font-size: 24px;
    }

    /deep/.el-icon-lock {
        color: #0090ff;
        font-size: 24px;
    }

    /deep/.el-form-item__error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        position: absolute;
        top: 76%;
        left: 32px;
    }

    .login_btn_box {
        position: relative;

        .login_btn {
            width: 304px;
            height: 54px;
            line-height: 54px;
            font-size: 18px;
            color: #fff;
            background: #3591FF;
            border: none;
            padding: 0;
            margin: 0 27px;
            letter-spacing: 4px;
        }

        span {
            position: absolute;
            bottom: -30px;
            right: 24px;
            color: #0090ff;

            cursor: pointer;

            &:hover {
                color: #fff;
            }
        }
    }

}
</style>