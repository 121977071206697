<template>
    <div>
        <!-- 渲染报警中心相关的页面 -->
        <router-view></router-view>
    </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
div {
    height: 100%;
}
</style>